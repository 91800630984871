<template lang="pug">
.video-wrap(v-if="blok" v-editable="blok" :class="blok.style")
	.video-pic2(v-if="!videoToggle && blok.preview_image.filename")
		picture
			nuxt-img(provider="storyblok" :class="blok.radial_filter ? 'radial' : ''" :src="blok.preview_image.filename" :alt="blok.preview_image.alt" sizes="800px xs:400px sm:500px md:800px")
		.play-button
			ClientOnly
				SingaButton.is-primary.is-regular(@click="videoToggle = !videoToggle" icon-left="play") {{ blok.button_text }}
	.video-playing(v-if="videoToggle && blok.youtube_link")
		iframe(width='560' height='315' :src="`https://www.youtube.com/embed/${getYoutubeID(blok.youtube_link)}?rel=0;&autoplay=1`" frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture')
</template>
<script setup lang="ts">
defineProps({
	blok: {
		required: true,
		type: Object
	}
})
const videoToggle = ref(false)
const getYoutubeID = (url: string) => {
	const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
	const match = url.match(regExp)

	return (match && match[2].length === 11)
		? match[2]
		: null
}
</script>
<style lang="sass" scoped>
.video-wrap
	padding: $spacing-32 0
	max-width: 800px
	margin: auto
	&.consumer
		padding: $spacing-32 0
		aspect-ratio: 16/9
		.video-pic2
			position: relative
			picture
				display: block
				width: 100%
				&.radial:after
					content: ''
					position: absolute
					top: 0
					left: 0
					width: 100%
					height: 100%
					background: radial-gradient(circle, rgba(0,0,0,0) 20%, rgba(0,0,0,1) 61%, rgba(0,0,0,1) 100%), linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 20%, rgba(0,0,0,0) 80%, rgba(0,0,0,1) 100%)
				img
					width: 100%
					display: block
		.video-wrap, .picture
			max-height: 300px
		.play-button
			position: absolute
			top: 50%
			left: 50%
			transform: translate(-50%, -50%)
			display: flex
			align-items: center
			justify-content: center
			z-index: 1
			cursor: pointer
			button
				padding: 8px 24px
			.svg-wrap
				padding-right: 8px

		.video-playing
			position: relative
			aspect-ratio: 16/9
			iframe
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
	&.business
		.video
			margin-top: 100px
			margin-bottom: 60px
			text-align: center
			.h2
				max-width: 925px
				margin: auto
				max-width: 35ch
				@media (min-width: 600px) and (max-width: 768px)
					font-size: 32px
					line-height: 34px
			@media (min-width: 768px)
				margin-top: 150px
				margin-bottom: $spacing-32
				padding-bottom: $spacing-48
		.video-au
			margin-bottom: 0 !important
			padding-bottom: 0 !important
			@media (min-width: 768px)
				.video-wrap
					padding-top: 0 !important
		.video-pic2
			position: relative
			padding-bottom: 52%
			overflow: hidden
			picture
				position: absolute
				top: 0
				left: 0
				&.radial:after
					content: ''
					position: absolute
					top: 0
					left: 0
					width: 100%
					height: 100%
					background: radial-gradient(circle, rgba(0,0,0,0) 20%, rgba(0,0,0,1) 61%, rgba(0,0,0,1) 100%), linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 20%, rgba(0,0,0,0) 80%, rgba(0,0,0,1) 100%)
		.play-button
			position: absolute
			top: 50%
			left: 50%
			transform: translate(-50%,-50%)
			display: flex
			align-items: center
			justify-content: center
			z-index: 1
			cursor: pointer
			:deep(.button)
				padding: 8px 24px
				.button-wrapper .icon
					margin: 0!important
			.svg-wrap
				padding-right: 8px

		.video-playing
			position: relative
			padding-bottom: 52%
			margin: 0 $spacing-32
			@media (min-width: 768px)
				margin: 0 100px
			iframe
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
</style>
